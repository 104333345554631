import React, {
  useReducer,
  useMemo,
  createContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useTimer } from './Context/TimerContext';
import { SignInAttempt, SignUpAttempt, sendCode } from './api/authApi';
import { getUserInformation, getShowInfo } from './api/workFlowApi';
const GlobalContext = createContext();

const ActionTypes = {
  LOG_IN: 'LOG_IN',
  LOG_OUT: 'LOG_OUT',
  USER_DATA_SET: 'USER_DATA_SET',
  SHOW_ID_SET: 'SHOW_ID_SET',
  TIMER_ACTION_SET: 'TIMER_ACTION_SET',
  SET_USER_SHOW_LIST: 'SET_USER_SHOW_LIST',
  SET_CURRENT_SHOW_INFO: 'SET_CURRENT_SHOW_INFO',
};

function globalReducer(prevState, action) {
  switch (action.type) {
    case ActionTypes.LOG_IN:
      return {
        ...prevState,
        userToken: action.userToken,
      };
    case ActionTypes.SET_USER_INFO:
      return {
        ...prevState,
        userInfo: action.userInfo,
      };
    case ActionTypes.SHOW_ID_SET:
      return {
        ...prevState,
        showId: action.showId,
      };
    case ActionTypes.SHOW_DATA_SET:
      return {
        ...prevState,
        showData: action.showData,
      };

    case ActionTypes.LOG_OUT:
      return {
        ...prevState,
        userToken: null,
        userShowList: null,
      };

    case ActionTypes.TIMER_DATA_SET:
      return {
        ...prevState,
        timerState: action.timerState,
      };
    case ActionTypes.SET_CURRENT_SHOW_INFO:
      return {
        ...prevState,
        currentShowInfo: action.currentShowInfo,
      };

    case ActionTypes.TIMER_ACTION_SET:
      return {
        ...prevState,
        timerAction: action.timerAction,
        initialValue: action.initialValue,
      };
    default:
      return prevState;
  }
}

export function GlobalContextWrapper({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [notLoading, setNotLoading] = useState(false);
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [onAir, setOnAir] = useState(false);
  const [langSelected, setLangSelected] = useState('en');
  useEffect(() => {
    GlobalActions.restoreSession();
  }, []);

  const intervalIdRef = useRef(null);
  const startTimeRef = useRef(null);

  const startTimer = (data) => {
    if (!isRunning) {
      setIsRunning(true);
      clearInterval(intervalIdRef.current);
      const initialTime = Date.now() - data;
      startTimeRef.current = initialTime;

      intervalIdRef.current = setInterval(() => {
        const currentTime = Date.now();
        const elapsed = currentTime - initialTime;

        setTime(elapsed);
      }, 100);
    }
  };

  const stopTimer = (initialState) => {
    setIsRunning(false);
    clearInterval(intervalIdRef.current);
    setTime(initialState);
  };

  const resetTimer = () => {
    stopTimer();

    setTime(0);
  };

  const [state, dispatch] = useReducer(globalReducer, {
    userToken: null,
    userShowList: null,
    timerAction: null,
    showData: null,
    initialValue: 0,
    showId: null,
    notLoading: false,
    currentShowInfo: null,
    userShowList: [],
    userInfo: null,
  });

  // Create memoized functions for actions

  let eventSource;
  const GlobalActions = useMemo(
    () => ({
      emitSubscribeInit: async (data) => {
        if (!eventSource) {
          try {
            let baseUrl =
              process.env.REACT_APP_SERVER_API_URL +
              '/broadcast/sse' +
              '?showId=' +
              data.showId +
              '&jwt=' +
              data.userToken;

            eventSource = new EventSource(baseUrl);

            eventSource.onmessage = async (event) => {
              var dataFromApi = event.data;
     
          if(dataFromApi!=="undefined"){
            
        
          let parsedData = JSON.parse(dataFromApi);

              if (parsedData.ended) {
                await GlobalActions.getUserInformationAndPutItInContext();
                await GlobalActions.getShowInfo(data.showId);
              } else if (parsedData.pause === true) {
                stopTimer(parsedData.playedTime);
              } else if (parsedData.pause === false) {
                startTimer(parsedData.playedTime);
              }

              if (parsedData.onAir === true) {
                setOnAir(true);
              } else {
                setOnAir(false);
              }}
            };

            return () => {
              if (eventSource) {
                eventSource.close();
              }
            };
          } catch (err) {
            console.log(err);
          }
        }
      },

      getUserInformationAndPutItInContext: async () => {
        try {
        
          let userInformation = await getUserInformation();

          if (userInformation) {
            dispatch({
              type: ActionTypes.SET_USER_INFO,
              userInfo: userInformation.data.userData,
            });
          }
        } catch (error) {
          console.error('Error logging in:', error);
        }
      },
      setLang: async (data) => {
        setLangSelected(data);
      },
      getShowInfo: async (showId) => {
        const currentShowInfo = await getShowInfo(showId);
        if (currentShowInfo === 'show-not-exist') {
          return 'error';
        }

        dispatch({
          type: ActionTypes.SET_CURRENT_SHOW_INFO,
          currentShowInfo: currentShowInfo.data.currentShowInfo,
        });
        return currentShowInfo.data.currentShowInfo;
      },

      showIdSet: async (showId) => {
        dispatch({
          type: ActionTypes.SHOW_ID_SET,
          showId: showId,
        });
      },
      showWorkFlowSet: async (showData) => {
        if (!showData) {
          return;
        }

        let showDataParced = JSON.parse(showData);

        dispatch({
          type: ActionTypes.SHOW_DATA_SET,
          showData: showDataParced,
        });
      },

      restoreSession: async () => {
        setNotLoading(false);

        const authToken = localStorage.getItem('authToken');

        if (authToken?.length > 10) {
          try {
            await setIsLoggedIn(true);
            await dispatch({
              type: ActionTypes.LOG_IN,
              userToken: authToken,
            });
            const showId = await localStorage.getItem('showId');
            await GlobalActions.getUserInformationAndPutItInContext();
            await GlobalActions.showIdSet(showId);
            await GlobalActions.getShowInfo(showId);
          } catch (error) {
            console.error('Error restoring token:', error);
          }
        }

        setNotLoading(true);
      },

      logIn: async (data) => {
        try {
          let auth = await SignInAttempt(data);
          if (auth === 'bad credentials') {
            return auth;
          }

          const { authToken } = auth;

          await setIsLoggedIn(true);

          await localStorage.setItem('authToken', authToken);

          dispatch({
            type: ActionTypes.LOG_IN,
            userToken: authToken,
          });

          await GlobalActions.getUserInformationAndPutItInContext();
          await GlobalActions.getShowInfo(localStorage.getItem('showId'));
          await GlobalActions.showIdSet(localStorage.getItem('showId'));
          return 'ok';
        } catch (error) {
          return error;
        }
      },

      logOut: async (data) => {
        try {
          localStorage.clear('authToken');
          localStorage.clear('showId');
          dispatch({
            type: ActionTypes.LOG_OUT,
            userToken: null,
          });
          setIsLoggedIn(false);
    
        } catch (error) {
          console.error('Error logging in:', error);
        }
      },
      sendCode: async (data) => {
        try {
          let showId = localStorage.getItem('showId');
          let result = await sendCode(data);
          if (result.authToken) {
            const authToken = result.authToken;
   
            setIsLoggedIn(true);
            await localStorage.setItem('authToken', authToken);
            dispatch({
              type: ActionTypes.LOG_IN,
              userToken: authToken,
            });

            await GlobalActions.getShowInfo(showId);
            await GlobalActions.getUserInformationAndPutItInContext();
            await GlobalActions.showIdSet(showId);
          }
          return 'ok';
        } catch (error) {
          console.error('Error sending code:', error);
          return false;
        }
      },

      signUp: async (data) => {
    
        try {
          data.userType = 'user';
          let result = await SignUpAttempt(data);
          debugger;
          if (
            result === 'User already exists.' ||
            result === 'Code incorrect.'
          ) {
            return result;
          }

          let showId = await localStorage.getItem('showId');
          if (result.authToken) {
            const authToken = result.authToken;
         
            setIsLoggedIn(true);
            await localStorage.setItem('authToken', authToken);
            dispatch({
              type: ActionTypes.LOG_IN,
              userToken: authToken,
            });
   
            await GlobalActions.getShowInfo(showId);
            await GlobalActions.getUserInformationAndPutItInContext();
            await GlobalActions.showIdSet(showId);
          }
          return 'ok';
        } catch (error) {
          debugger;
          console.error('Error signing up:', error);
          return false;
        }
      },
    }),
    []
  );

  return (
    <GlobalContext.Provider
      value={{
        state,
        isLoggedIn,
        notLoading,
        GlobalActions,
        time,
        onAir,
        isRunning,
        langSelected,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalContext;
