import { useContext, useEffect, useState } from 'react';
import arrowBack from '../images/arrow-back.png';
import explainScreen from '../images/explain-screen.png';
import { useNavigate } from 'react-router-dom';
import GlobalContext from '../GlobalContext';
import { vocabulary } from '../helper/vocabulary';
export default function HowItWork() {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate('/main');
  };

  const { langSelected } = useContext(GlobalContext);

  const [lang, setLang] = useState(0);

  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);

  return (
    <div className="how-it-works-main">
      <div className="header-how-it-works">
        <h4>{vocabulary.howItWorks[lang]}</h4>
        <img
          className="arrow-back"
          src={arrowBack}
          alt="arrow back"
          onClick={handleBackClick}
        />
      </div>
      <img
        className="explain-screen"
        src={explainScreen}
        alt="explain -screen"
        width={'206px'}
      />

      <div className="text-how-it-works">
        <p>{vocabulary.howItsWorkText[lang]}</p>
        <button className="button-i-understand" onClick={handleBackClick}>
          {vocabulary.imUnderstand[lang]}
        </button>
      </div>
    </div>
  );
}
