import React, { useContext, useEffect, useState } from 'react';
import { sendVote } from '../api/workFlowApi';
import { useNavigate, useLocation } from 'react-router-dom';
import GlobalContext from '../GlobalContext';
import ButtonUpCard from '../Components/ButtonUpCard';
import goodIcon from '../images/good-icon.png';
import badIcon from '../images/bad-icon.png';
import smallTriangle from '../images/small-triangle.png';
import { vocabulary } from '../helper/vocabulary';

const FormToSend = () => {
  const location = useLocation();
  const { mark, time } = location.state;
  const isUp = mark === 'Up';
  const { state } = useContext(GlobalContext);
  const [text, setText] = useState('');
  const navigate = useNavigate();

  const { langSelected } = useContext(GlobalContext);

  const [lang, setLang] = useState(0);

  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleSubmit = async () => {
    let dataToSend = {
      score: isUp,
      annotation: text,
      showId: state.showId,
      time: time,
    }
    await sendVote(dataToSend);
    navigate('/main');
  };

  const handleCancel = () => {
    navigate('/main');
  };
  return (
    <div className='all-screen-comment-sent'>
    <div className="form-to-send-screen">
      <ButtonUpCard lang={lang} />
      <div className="comment-send">
        <img
          src={isUp ? goodIcon : badIcon}
          alt="vote for"
          width="50vh"
          height="50vh"
        />
      </div>
      <div className="comment-send-small-triangle">
        <img src={smallTriangle} alt="vote for" width="20px" height="20px" />{' '}
      </div>
      <div className="form-to-send">
        <textarea
          className="textarea-comment-send"
          rows="8"
          cols="35"
          value={text}
          onChange={handleTextChange}
          placeholder={vocabulary.whatDoYouThink[lang]}
        />
        <br />
      </div>
      <div className="button-group">
        <button className="button-send-cancel" onClick={handleCancel}>
          {vocabulary.cancel[lang]}
        </button>
        <button className="button-send" onClick={handleSubmit}>
          {vocabulary.send[lang]}
        </button>
      </div>
    </div>
    </div>
  );
};

export default FormToSend;
