import React, { useContext, useEffect, useState } from 'react';
import logoIcon from '../images/logo.png';

import Layout from './Layout/Layout';
import GlobalContext from '../GlobalContext';

export default function ErrorScreen() {
 
  const { langSelected } = useContext(GlobalContext);
  const [lang, setLang] = useState(0);
 
  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);





  return (

    <Layout showMenu={true} lang={0}>
      
      <div className="logo-container">
          <img src={logoIcon} alt="message-icon" className="chat-icon" />
        
        </div>
       <div className='error-page-text'> Escanea el QR para acceder a la proyección</div>
      </Layout>

  );
}
