export default function Privacy({ lang }) {
  return lang === 1 ? (
    <>
      <h4> 1. Introducción</h4>
      Bienvenido a Screenings, una aplicación proporcionada por ARTEFACTO
      FÍLMICO SL B44983641. Al acceder o utilizar Screenings, usted acepta estar
      vinculado por estos Términos y Condiciones y nuestra Política de
      Privacidad.
      <h4>2. Privacidad y Protección de Datos</h4>
      ARTEFACTO FÍLMICO SL B44983641 respeta su privacidad y se compromete a
      proteger sus datos personales. Los datos recogidos a través de Screenings
      se utilizan exclusivamente para proporcionar y mejorar nuestros servicios.
      No compartiremos su información con terceros sin su consentimiento, salvo
      en los casos exigidos por ley o para proteger nuestros derechos.
      <h4>3. Derechos de Propiedad Intelectual</h4>
      El contenido de Screenings, incluyendo textos, gráficos, logos, y
      software, es propiedad de ARTEFACTO FÍLMICO SL B44983641 o se utiliza bajo
      licencia. Usted se compromete a no copiar, modificar, distribuir o usar
      este contenido sin nuestro consentimiento expreso.
      <h4>4. Uso Aceptable</h4>
      Usted se compromete a no utilizar Screenings de manera que cause daño, sea
      ilegal, fraudulenta o perjudicial, o en conexión con cualquier propósito o
      actividad ilegal, fraudulenta o perjudicial.
      <h4>5. Limitación de Responsabilidad</h4>
      ARTEFACTO FÍLMICO SL B44983641 no será responsable de cualquier daño
      indirecto, incidental, especial o consecuente que surja del uso o la
      incapacidad de usar Screenings.
      <h4>6. Modificaciones a los Términos</h4>
      Nos reservamos el derecho de modificar estos Términos y Condiciones en
      cualquier momento. Los cambios serán efectivos una vez publicados en
      Screenings.
      <h4>7. Legislación Aplicable</h4>
      Estos Términos se regirán e interpretarán de acuerdo con las leyes de
      España, y cualquier disputa relacionada estará sujeta a la jurisdicción
      exclusiva de los tribunales españoles.
    </>
  ) : (
    <>
      <h4>1. Introduction</h4>
      Welcome to Screenings, an application provided by ARTEFACTO FÍLMICO SL
      B44983641 By accessing or using Screenings, you agree to be bound by these
      Terms and Conditions and our Privacy Policy.
      <h4>2. Privacy and Data Protection</h4>
      ARTEFACTO FÍLMICO SL B44983641 respects your privacy and is committed to
      protecting your personal data. The data collected through Screenings is
      used exclusively to provide and improve our services. We will not share
      your information with third parties without your consent, except as
      required by law or to protect our rights.
      <h4>3. Intellectual Property Rights</h4>
      The content of Screenings, including text, graphics, logos, and software,
      is the property of ARTEFACTO FÍLMICO SL B44983641 or is used under
      license. You agree not to copy, modify, distribute, or use this content
      without our express consent.
      <h4>4. Acceptable Use</h4>
      You agree not to use Screenings in a way that causes harm, is unlawful,
      fraudulent, or harmful, or in connection with any unlawful, fraudulent, or
      harmful purpose or activity.
      <h4>5. Limitation of Liability</h4>
      ARTEFACTO FÍLMICO SL B44983641 will not be liable for any indirect,
      incidental, special, or consequential damages arising from the use or the
      inability to use Screenings.
      <h4>6. Changes to Terms</h4>
      We reserve the right to modify these Terms and Conditions at any time.
      Changes will become effective once posted on Screenings.
      <h4>7. Governing Law</h4>
      These Terms shall be governed by and construed in accordance with the laws
      of Spain, and any disputes relating to these terms shall be subject to the
      exclusive jurisdiction of the Spanish courts.
    </>
  );
}
