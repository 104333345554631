import React, { useContext, useEffect } from 'react';
import { sendVote } from '../api/workFlowApi';
import GlobalContext from '../GlobalContext';
import goodIcon from '../images/good-icon.png';
import badIcon from '../images/bad-icon.png';
import ButtonUpCard from './ButtonUpCard';
import goodMessage from '../images/good-message.png';
import badMessage from '../images/bad-message.png';

function Timer({ swipeDirection, lang }) {
  const { state, time } = useContext(GlobalContext);

  let score = null;

  useEffect(() => {
    if (swipeDirection) {
      switch (swipeDirection) {
        case 'Left':
          score = 'true';
          break;
        case 'Right':
          score = 'false';
          break;
        default:
      }

      let dataToSend = {
        score: score,
        annotation: null,
        showId: state.showId,
        time: time,
      };
      if (swipeDirection === 'Left' || swipeDirection === 'Right') {
        sendVote(dataToSend);
      }
    }
  }, [swipeDirection]);

  return (
    <div className="screen-timer">
      <ButtonUpCard time={time} state={state} lang={lang} />

      <div className="container-of-touchable">
        <div className="comment-up">
          <img src={goodMessage} alt="vote for" width="50vh" height="50vh" />
        </div>
        <div className="screen">
          <div className={'left-arrow-hided'}>
            <img src={badIcon} alt="vote for" width="50vh" height="50vh" />
          </div>
          {/*   <div className="center-line"></div>
           */}
          <div className={'right-arrow-hided'}>
            <img src={goodIcon} alt="vote for" width="50vh" height="50vh" />
          </div>
        </div>
        <div className="comment-up">
          <img src={badMessage} alt="vote for" width="50vh" height="50vh" />
        </div>
      </div>
    </div>
  );
}

export default Timer;
