import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSearchParams } from 'react-router-dom';
import arrowBack from '../images/arrow-back.png';
import { passwordRecovery } from '../api/authApi';
export default function PasswordRecoveryScreen() {
 
  const [email, setUserEmail] = useState('');

  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState(false);
  const [queryParameters] = useSearchParams();
  const [ setShowSignUpError] = useState(false);
  const showId = queryParameters.get('id') + '';
  const [newPasswordSended, setNewPasswordSended] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const res = async () => {
      let result = await passwordRecovery({ email: email });
  
      if (result.message === 'ok') {
        setNewPasswordSended(true);
      }
    };
    res();
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setShowSignUpError(false);

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (emailRegex.test(email) || email === '') {
      setUserEmail(email);
      setShowEmailErrorMessage(false);
    } else {
      setShowEmailErrorMessage(true);
    }
  };

  const handleBackToLogin = () => {
    navigate('/login/?id=' + showId);
  };

  return (
    <div className="login-screen">
      <div className="arrow-back-div" onClick={handleBackToLogin}>
        <img className="arrow-back-recovery" src={arrowBack} alt="logo" />
      </div>

      <div className="hello-text-login">
        <div>
          <h3>Olvido su contraseña</h3>
        </div>
        <div className="recovery-screen-text">
          No se preocupe. Introduce tu dirección de correo electrónico y te
          enviaremos un código para restablecer la contraseña.
        </div>
      </div>

      <div className="login-group">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="labels-input">
              <label htmlFor="email">E-mail</label>
            </div>
            <div className="input-div">
              <input
                className="input-login"
                type="text"
                id="email"
                autoComplete="email"
                placeholder="Ingrese su email"
                onChange={handleEmailChange}
                required
                disabled={newPasswordSended}
              />
            </div>
            {newPasswordSended && (
              <div className="error-message">
                Nueva contraseña ha sido enviada.
              </div>
            )}
            {showEmailErrorMessage && (
              <div className="error-message">Formato de correo inválido.</div>
            )}

            <button type="submit" className="login-button">
              Restablecer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
