import formatDate from '../helper/dateToAmPmConverter';
import formatTime from '../helper/formatTime';
import GlobalContext from '../GlobalContext';
import React, { useContext } from 'react';
import { vocabulary } from '../helper/vocabulary';
export default function ButtonUpCard({ lang }) {
  const { state, time } = useContext(GlobalContext);

  
  return (
    <div className="div-inside-button-shows">
      <div className="timer-names">
        <div className="header-text-line">
          {state.currentShowInfo.videoId.videoName?.slice(0, 25)}&nbsp; | &nbsp;
          <div className="show-name-main">
            {state.currentShowInfo.showName.slice(0, 25)}
          </div>
        </div>

        <div className="header-text-line-1">
          {vocabulary.duration[lang]}:&nbsp;
          {formatTime(state.currentShowInfo.videoId.videoDuration).slice(0, -4)}
        </div>

        <div className="header-text-line-1">
          {formatDate(state.currentShowInfo.showStartTime)}
        </div>
      </div>
      <div className="timer-in-card">{formatTime(time).slice(0, -4)}</div>
    </div>
  );
}
