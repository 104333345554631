import serviceAxios from './serviceAxios';

export async function sendVote(data) {
  try {
    const response = await serviceAxios.post(`/private/vote`, data);

    if (response.status !== 200) {
      console.error('[!] Error:', response.status, 'returned');
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (error) {
    console.error('[!] Error:', error);
    throw new Error('An error occurred during API call');
  }
  return 'ok';
}
export async function getUserInformation() {
  try {
    const response = await serviceAxios.get(`/private/get-user-information`);

    if (response.status !== 200) {
      console.error('[!] Error:', response.status, 'returned');
      throw new Error(`Request failed with status ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error('[!] Error:', error);
    throw new Error('An error occurred during API call');
  }
}

export async function getShowInfo(data) {
  if (!data){return "show-not-exist"}
  try {
    const response = await serviceAxios.get(
      '/private/get-show-information?id=' + data
    );

    if (response.status !== 200) {
      console.error('[!] Error:', response.status, 'returned');
      throw new Error(`Request failed with status ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error('[!] Error:', error);
    try {
      if (error.response.data.message == 'Show not found') {
        return 'show-not-exist';
      }
    } catch {}

    throw new Error('An error occurred during API call');
  }
}

export async function postShowParticipation(data) {
  try {
    const response = await serviceAxios.post(
      `/private/post-show-participation`,
      data
    );
    if (response.status !== 200) {
      console.error('[!] Error:', response.status, 'returned');
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (error) {
    console.error('[!] Error:', error);
    throw new Error('An error occurred during API call');
  }
}

export async function getReaction(data) {
  try {
    const response = await serviceAxios.get(
      '/private/get-show-reaction?id=' + data
    );

    if (response.status !== 200) {
      console.error('[!] Error:', response.status, 'returned');
      throw new Error(`Request failed with status ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error('[!] Error:', error);
    try {
      if (error.response.data.message == 'Show not found') {
        return 'show-not-exist';
      }
    } catch {}

    throw new Error('An error occurred during API call');
  }
}
export async function getImage(data) {
  try {
    const response = await serviceAxios.get('/private/get-image?id=' + data);

    if (response.status !== 200) {
      console.error('[!] Error:', response.status, 'returned');
      throw new Error(`Request failed with status ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error('[!] Error:', error);

    return error.response.data.message;
  }
}
