import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GlobalContext from '../GlobalContext';
import arrowBack from '../images/arrow-back.png';
import { useSearchParams } from 'react-router-dom';

export default function CodeConfirmation() {
  const { GlobalActions, state } = useContext(GlobalContext);
  const [codeInForm, setCodeInForm] = useState('');
  const [showLoginError, setShowLoginError] = useState(false);
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');


  useEffect(() => {
    GlobalActions.sendCode(code);
   
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let res = await GlobalActions.sendCode({
      code: codeInForm,
    });
    if (res === 'Code incorrect.') {
      setShowLoginError(true);
    }
  };

  const navigate = useNavigate();
  const handleBackToLogin = () => {
    navigate('/main');
  };
  setTimeout(handleBackToLogin, 4000);

  return (
    <div className="login-screen">
      <div className="arrow-back-div" onClick={handleBackToLogin}>
        <img className="arrow-back" src={arrowBack} alt="logo" />
      </div>
      <div className="text-email-sent">
        <h3>Activamos su codigo</h3>
      </div>
    </div>
  );
}
