import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import arrowBack from '../images/arrow-back.png';

export default function EmailSent() {
  const navigate = useNavigate();
  const handleBackToLogin = () => {
    navigate('/login/');
  };
  setTimeout(handleBackToLogin, 4000);
  return (
    <div className="login-screen">
      <div className="arrow-back-div" onClick={handleBackToLogin}>
        <img className="arrow-back" src={arrowBack} alt="logo" />
      </div>
      <div className="text-email-sent">
        <h3>
          Revise, por favor su buzón de correo, le acabamos de enviar un mensaje
          para activar su cuenta
        </h3>
      </div>
    </div>
  );
}
