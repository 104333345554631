import { vocabulary } from '../../helper/vocabulary';
import threePointIcon from '../../images/three-point-icon.png';
export default function FooterMainScreen({ navigate, lang }) {
  const handleEnter = () => {
    navigate('/show');
  };

  return (
    <div className="footer-main-screen">
      <div className="div-block-footer">
        <div className="footer-text">
          {vocabulary.itStartsRightNow[lang]}&nbsp;{vocabulary.wait[lang]}
        </div>
      </div>
      {/* <button className="button-main">
        <div className="header-text-line" onClick={handleEnter}>
          Ingresar
        </div>
      </button>*/}
    </div>
  );
}
