import axios from 'axios';

const baseUrl = process.env.REACT_APP_SERVER_API_URL;

const config = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
};

export async function SignInAttempt(data) {
  const apiUrl = baseUrl + `/public/login-user`;

  let response;
  try {
    response = await axios.post(apiUrl, data, config);

    if (response.status !== 200) {
      console.error('[!] Error:', response.status, 'returned');
      return response;
    }
  } catch (error) {
    if ((error.message = 'Request failed with status code 401')) {
      return 'bad credentials';
    }
    console.log(error, 'error ocurred');
  }

  return response.data;
}

export async function SignUpAttempt(data) {


  const apiUrl = baseUrl + `/public/signup-user`;

  let response;
  try {
    response = await axios.post(apiUrl, data, config);
    debugger;
    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
}
export async function checkToken(data) {
  const apiUrl = baseUrl + `/public/check-token-user`;

  let response;

  try {
    response = await axios.post(apiUrl, data, config);

    if (response.status !== 200) {
      console.error('[!] Error:', response.status, 'returned');
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (error) {
    if ((error.message = 'Request failed with status code 400')) {
      return 'bad credentials';
    }

    throw new Error('An error occurred during API call');
  }

  return response.data;
}
export async function passwordRecovery(data) {
  const apiUrl = baseUrl + `/public/password-recovery`;

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  };
  let response;

  try {
    response = await axios.put(apiUrl, data, config);
  } catch (error) {
    console.error('[!] Error:', error);
    if (error.response.status === 401) {
      return 'bad credentials';
    } else {
      throw new Error('An error occurred during API call');
    }
  }

  return response.data;
}

export async function sendCode(data) {
  try {
    const response = await axios.get(
      baseUrl + '/public/send-code?code=' + data
    );
    const result = response.data;

    return result;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}
