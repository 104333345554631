export default function formatDate(inputDate) {
  const parsedDate = new Date(inputDate);

  const monthNames = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
  ];

  const day = parsedDate.getDate();
  const month = monthNames[parsedDate.getMonth()];
  const year = parsedDate.getFullYear();
  const hours = parsedDate.getHours();
  const minutes = parsedDate.getMinutes();

  const amOrPm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;

  const formattedDate = `${day}/${month}/${year} ${formattedHours}:${
    minutes < 10 ? '0' : ''
  }${minutes} ${amOrPm}`;

  return formattedDate;
}
