import logoIcon from '../images/logo.png';
import load from '../images/load.gif';

export default function SplashScreen() {
  return (
    <div className="splash">
      <div className="splash-image-block">
        <div className="logo-container">
          <img src={logoIcon} alt="message-icon" className="chat-icon" />
        </div>
        <img className="image-splash-load" src={load} alt="splash gif" />
      </div>

      <div className="splash-text">
        Derechos reservados a Artefacto film @2023
      </div>
    </div>
  );
}
