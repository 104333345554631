import { Routes, Route, BrowserRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import FormToSend from './Screens/FormToSendScreen';
import Login from './Screens/LoginScreen';
import { useContext } from 'react';
import GlobalContext from './GlobalContext';
import Card from './Screens/CardScreen';
import SignUpScreen from './Screens/SignupScreen';
import ErrorScreen from './Screens/ErrorScreen';
import SplashScreen from './Screens/SplashScreen';
import MainScreen from './Screens/MainScreen';
import MyShows from './Screens/MyShows';
import EndedShow from './Screens/EndedShow';
import PrivacyAndTermsScreen from './Screens/PrivacyAndTermsScreen';
import HowItWork from './Screens/HowItsWork';
import PasswordRecoveryScreen from './Screens/PasswordRecoveryScreen';
import EmailSent from './Screens/EmailSent';
import CodeConfirmation from './Screens/CodeConfirmation';

function AppRoutes() {
  const { isLoggedIn, notLoading, onAir } = useContext(GlobalContext);
  const { GlobalActions } = useContext(GlobalContext);
  const userLanguage = navigator.language || navigator.userLanguage;
  if (userLanguage.startsWith('es')) {
    GlobalActions.setLang('es');
  }

  return (
    <BrowserRouter>
      <Routes>
        {!notLoading ? (
          <Route path="*" element={<SplashScreen />} />
        ) : isLoggedIn ? (
          onAir ? (
            <>
              <Route path="/my-shows" element={<MyShows />} />
              <Route path="/signup" element={<SignUpScreen />} />
              <Route path="/formtosend" element={<FormToSend />} />
              <Route path="/main" element={<Card />} />
              <Route path="/how-it-works" element={<HowItWork />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/privacy-and-terms"
                element={<PrivacyAndTermsScreen />}
              />
           <Route path="*" element={<Card />} />
           
            </>
          ) : (
            <>
              <Route path="/main" element={<MainScreen />} />
              <Route path="/" element={<MainScreen />} />
              <Route path="/formtosend" element={<MainScreen />} />
              <Route path="/how-it-works" element={<HowItWork />} />
              <Route path="/error" element={<ErrorScreen />} />
              <Route path="/login" element={<Login />} />
              <Route path="/my-shows" element={<MyShows />} />
              <Route path="/ended-show" element={<EndedShow />} />
              <Route
                path="/privacy-and-terms"
                element={<PrivacyAndTermsScreen />}
              />
              <Route path="/signup" element={<SignUpScreen />} />
              <Route path="/activate" element={<CodeConfirmation />} />
            </>
          )
        ) : (
          <>
            <Route path="/signup" element={<SignUpScreen />} />
            <Route path="/login" element={<Login />} />
            <Route path="/activate" element={<CodeConfirmation />} />
            <Route path="/email-sent" element={<EmailSent />} />
            <Route path="/recovery" element={<PasswordRecoveryScreen />} />
            <Route path="*" element={<Login />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
