import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GlobalContext from '../GlobalContext';
import Layout from './Layout/Layout';
import FooterMainScreen from './Layout/FooterMainScreen';
import formatTime from '../helper/formatTime';
import { getImage, postShowParticipation } from '../api/workFlowApi';
import howItWorkIcon from '../images/how-it-work-icon.png';
import blackCicle from '../images/black-circle.png';
import { vocabulary } from '../helper/vocabulary';

export default function MainScreen() {
  const { langSelected } = useContext(GlobalContext);
  const [lang, setLang] = useState(0);
  const [load, setLoad] = useState(false);
  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);

  const { state, GlobalActions } = useContext(GlobalContext);
  const [showId, setShowId] = useState('');
  const [blobUrl, setBlobURL] = useState(null);

  const navigate = useNavigate();

  const showDuration = formatTime(
    state.currentShowInfo?.videoId?.videoDuration || 0
  ).slice(0, -4);

  useEffect(() => {



    if (state.showId) {
         
      const result = async () => {
    
        let image = await getImage(state.currentShowInfo.videoId._id);
        if (image) {
          let bufferObject = JSON.parse(image.data[0].blob);
          const uint8Array = new Uint8Array(bufferObject.data);
          let blob = new Blob([uint8Array], { type: bufferObject.type });
          setBlobURL(URL.createObjectURL(blob));
        }
        let show_id = state.currentShowInfo._id;
        let isShowInArray = false;
     
        state.userInfo?.showsParticipated?.map((val) => {
          if (val === show_id) {
            isShowInArray = true;
          }
        });

        if (state.userInfo && !isShowInArray) {
          await postShowParticipation({ showId: state.currentShowInfo.showId });
          const userInfo = await GlobalActions.getUserInformationAndPutItInContext();
          GlobalActions.emitSubscribeInit(state);
        }else{GlobalActions.emitSubscribeInit(state);}
      }; 

      result();
    }else{
      
   
      navigate("/error")}
      setLoad(true)

  }, []);

  const howItsWorksClick = () => {
    navigate('/how-it-works');
  };


  return    load&&<Layout
      showCurrentSessionInfo={true}
      showMenu={true}
      showDuration={showDuration}
      lang={lang}
      videoName={state.currentShowInfo?.videoId.videoName}
    >
      <div className="main-screen">
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '40vh',
            flexDirection: 'column',
            backgroundImage: `url(${blobUrl})`,
            backgroundSize: 'auto',
            backgroundPosition: 'center',
            marginTop: '7vh',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div
            style={{
              margin: '3px',
              padding: '3px',
              justifyContent: 'center',
              backgroundColor: ' #fe1a79',
              alignItems: 'center',
              display: 'flex',
              width: '150px',
              fontSize: 'small',
              color: 'white',
            }}
            onClick={howItsWorksClick}
          >
            {blobUrl && (
              <img
                src={howItWorkIcon}
                alt="How it works?"
                width="10"
                height="10"
              />
            )}
            {vocabulary.howItWorks[lang]}
          </div>
        </div>

        <FooterMainScreen navigate={navigate} lang={lang} />
      </div>
    </Layout>}
  

