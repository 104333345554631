import React, { useContext, useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import GlobalContext from '../GlobalContext';
import logoIcon from '../images/logo.png';
import GoogleAuth from '../Components/GoogleAuth';
import { vocabulary } from '../helper/vocabulary';

export default function Login() {
  const navigate = useNavigate();

  const { state, GlobalActions } = useContext(GlobalContext);
  const [showLoginError, setShowLoginError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setUserPassword] = useState('');
  const [queryParameters] = useSearchParams();
  const showId = queryParameters.get('id') + '';

  const { langSelected } = useContext(GlobalContext);

  const [lang, setLang] = useState(0);

  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);

  useEffect(() => {
    const getShow = async () => {
      let id = await localStorage.getItem('showId');

      if (id !== showId && showId !== 'null') {
        await localStorage.setItem('showId', showId);
        await GlobalActions.restoreSession();
      }

      if (state.userToken) {
        await GlobalActions.showIdSet(showId);
        navigate('/main');
      }
    };

    getShow();
  }, []);
  const myRef = useRef(null);
  const handleSubmit = async (e) => {
  
    e.preventDefault();

    let res = await GlobalActions.logIn({
      email: email,
      password: password,
      showId: showId,
    });

    if (res === 'bad credentials') {
      setShowLoginError(true);
    } else {
      navigate('/main/?id=' + showId);
    }
  };

  const handleOnClick = () => {
    navigate('/signup');
  };

  const handleSetShowLogInError = () => {
    setShowLoginError(true);
  };

  return (
    <div className="login-container">
      <div className="logo-container">
        <img src={logoIcon} alt="message-icon" className="chat-icon" />
      </div>
      <div className="hello-text-login">
        <div>
          <h3>{vocabulary.startSession[lang]}</h3>
        </div>
      </div>

      <div className="login-group">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="labels-input">
              <label htmlFor="name">{vocabulary.email[lang]}</label>
            </div>
            <div className="input-div">
              <input
                className="input-login"
                type="text"
                id="e-mail"
                autoComplete="email"
                placeholder={vocabulary.email[lang]}
                onChange={(e) => {
                  setShowLoginError(false);
                  setEmail(e.target.value);
                }}
                required
              />
            </div>
            <div className="labels-input">
              <label className="" htmlFor="age">
                {vocabulary.password[lang]}
              </label>
            </div>
            <div className="input-div">
              <input
                className="input-login"
                type="password"
                id="password"
                autoComplete="current-password"
                placeholder={vocabulary.password[lang]}
                onChange={(e) => {
                  setShowLoginError(false);
                  setUserPassword(e.target.value);
                }}
                required
              />
            </div>
            {showLoginError && (
              <div className="error-message">
                {vocabulary.errorMailPassword[lang]}
              </div>
            )}
            <div
              className="div-forgot-password"
              onClick={() => {
                navigate('/recovery');
              }}
            >
              <p>{vocabulary.passwordForgotten[lang]}</p>
            </div>

            <button type="submit" className="login-button">
              {vocabulary.startSession[lang]}
            </button>
          </div>
        </form>
      </div>
      <div onClick={handleOnClick} className="div-signup-me">
        <p>{vocabulary.doNotHaveAccount[lang]}</p>
      </div>

      <GoogleAuth
        login={true}
        setShowLogInError={handleSetShowLogInError}
        lang={lang}
      />
    </div>
  );
}
