import React, { useContext, useState } from 'react';

import HamburgerMenu from './HamburgerMenu';
import GlobalContext from '../../GlobalContext';

function Header({
  showCurrentSessionInfo,
  showMenu,
  showDuration,
  lang,
  videoName,
}) {
  const { state, isLoggedIn, GlobalActions } = useContext(GlobalContext);

  let showName = state.currentShowInfo?.showName?.toUpperCase();

  let showStartTime = state.currentShowInfo?.showStartTime;
  
    return (
      <HamburgerMenu
        videoName={videoName}
        showName={showName}
        showStartTime={showStartTime}
        showCurrentSessionInfo={showCurrentSessionInfo}
        showMenu={showMenu}
        showDuration={showDuration}
        lang={lang}
        currentStatus={state.currentShowInfo?.currentStatus}
      />
    );
  
}

export default Header;
