import { useContext, useEffect, useState } from 'react';
import Privacy from '../text-values/privacyAndTerms';
import Layout from './Layout/Layout';
import GlobalContext from '../GlobalContext';
import { vocabulary } from '../helper/vocabulary';

export default function PrivacyAndTermsScreen() {
  const { langSelected } = useContext(GlobalContext);

  const [lang, setLang] = useState(0);

  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);

  return (
    <Layout showCurrentSessionInfo={false} showMenu={true} lang={lang}>
      <div className="privacy-and-terms">
        <h2>{vocabulary.privacyAndTerms[lang]}</h2>
        <div className="privacy-and-terms-text">
          <Privacy lang={lang} />
        </div>
      </div>
    </Layout>
  );
}
