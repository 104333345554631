import React, { useContext, useEffect } from 'react';
import GlobalContext from './GlobalContext';
import AppRoutes from './Routes';
import './Layout.css';
import { GoogleOAuthProvider } from '@react-oauth/google';

export default function App() {
  const { state, GlobalActions } = useContext(GlobalContext);

  useEffect(() => {
    GlobalActions.restoreSession();
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE}>
      <AppRoutes />
    </GoogleOAuthProvider>
  );
}
