import React, { useContext, useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import Timer from '../Components/Timer';
import { useNavigate } from 'react-router-dom';
import GlobalContext from '../GlobalContext';

const Card = ({}) => {
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState('black');
  const colorChangeDelay = parseInt(process.env.REACT_APP_COLOR_DELAY);
  const navigate = useNavigate();

  const { state, GlobalActions, time, isRunning } =
    useContext(GlobalContext);

  useEffect(() => {
    GlobalActions.emitSubscribeInit(state);
  }, []);


  const { langSelected } = useContext(GlobalContext);
  const [lang, setLang] = useState(0);
 
  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);












  const timeout = (dir) => {
    setTimeout(() => {
      if (dir === 'Down' || dir === 'Up') {
        let mark = dir;
        setSwipeDirection(null);
        navigate('/formtosend', {
          state: { mark, time },
        });
      }
      setBackgroundColor('black');
    }, colorChangeDelay);
  };

  const handlers = useSwipeable({
    onSwipedLeft: (event) => {
      if (event.deltaX < 50 && isRunning) {
        setBackgroundColor('red');
        setSwipeDirection('Left');
        timeout();
      }
    },
    onSwipedRight: (event) => {
      if (event.deltaX > 50 && isRunning) {
        setBackgroundColor('green');
        setSwipeDirection('Right');
        timeout();
      }
    },
    onSwipedUp: async (event) => {
      if (isRunning) {
        setSwipeDirection('Up');
        timeout('Up');
        setBackgroundColor('green');
      }
    },
    onSwipedDown: (e) => {
      if (isRunning) {
        setBackgroundColor('red');
        setSwipeDirection('Down');
        timeout('Down');
      }
    },
    delta: 40,
    preventScrollOnSwipe: true,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    trackTouch: true,
  });

  return (
    <div
      {...handlers}
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: backgroundColor,
        touchAction: 'pan-y',
        transition: 'background-color 0.4s ease-out',
      }}
    >
      <Timer swipeDirection={swipeDirection} lang={lang} />
    </div>
  );
};

export default Card;
