import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import GlobalContext from '../../GlobalContext';
import { useContext } from 'react';
import formatDate from '../../helper/dateToAmPmConverter';
import { vocabulary } from '../../helper/vocabulary';
import formatTime from '../../helper/formatTime';

function HamburgerMenu({
  showName,
  showStartTime,
  showCurrentSessionInfo,
  showMenu,
  showDuration,
  currentStatus,
  lang,
  videoName,
}) {



  const [userNameSurname, setUserNameSurname] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [endedShow, setEndedShow] = useState(false);
  const { state, isLoggedIn, GlobalActions } = useContext(GlobalContext);
  const [currentMenuActive, setCurrentMenuActive] = useState(null);
  const navigate = useNavigate();

  let location = useLocation();




/*
  useEffect(() => {
    switch (location.pathname) {
      case '/my-shows':
        setCurrentMenuActive(vocabulary.sessions[lang]);
        break;

      case '/ended-show':
        setEndedShow(true);
        setUserNameSurname(
          state.userInfo.userName + ' ' + state.userInfo.surname
        );
        break;

      default:
        break;
    }
  }, []);
*/
  const handleLanguageChange = () => {
    GlobalActions.setLang(lang == 0 ? 'es' : 'en');
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const onClickMyShows = () => {
    toggleMenu();
    navigate('/my-shows');
  };

  const onClickMain = () => {
    toggleMenu();
    navigate('/main');
  };

  const onClickExit = () => {
    toggleMenu();
    GlobalActions.logOut();
  };
  const onClickPrivacyAndTerms = () => {
    toggleMenu();
    navigate('/privacy-and-terms');
  };

  return (
    <>
      <div className="hamburger-header-icon-main">
        <div className="header-text">
          {showCurrentSessionInfo && (
            <>
              <div
                className={
                  currentStatus !== 'ended'
                    ? 'header-text-line'
                    : 'ended-show-status'
                }
              >
                {vocabulary.sessions[lang]} :
                {currentStatus === 'ended' ? vocabulary.ended[lang] : null}
              </div>
              <div className="header-text-line">
                {state.currentShowInfo?.videoId?.videoName?.slice(0, 16)}
                &nbsp; | &nbsp;
                <div className="show-name-main">{showName?.slice(0, 16)}</div>
              </div>

              <div className="header-text-line-1">
                {vocabulary.duration[lang]}&nbsp;{showDuration}
              </div>

              <div className="header-text-line-1">
                {formatDate(showStartTime)}
              </div>
            </>
          )}
          <div className="header-text-line">{currentMenuActive}</div>
          {endedShow && (
            <>
              <div className="header-text-line">
                {vocabulary.thanksForJoin[lang]}
              </div>
              <div className="header-text-line">{userNameSurname}</div>
            </>
          )}
        </div>
        {showMenu && (
          <div className="hamburger-icon" onClick={toggleMenu}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        )}
      </div>
      <div className={`hamburger-menu ${isOpen ? 'open' : ''}`}>
        <div className="hamburger-icon" onClick={toggleMenu}>
          X
        </div>
        {isOpen && (
          <ul className="menu-items">
            <li onClick={onClickMain}>{vocabulary.actualShow[lang]}</li>
            <div className="line-menu" />
            <li onClick={onClickMyShows}>{vocabulary.myShows[lang]}</li>
            <div className="line-menu" />
            <li onClick={onClickPrivacyAndTerms}>
              {vocabulary.privacyAndTerms[lang]}{' '}
            </li>
            <div className="line-menu" />
            <li onClick={handleLanguageChange}>
              {lang === 0 ? 'Español' : 'English'}
            </li>
            <div className="line-menu" />
            <li onClick={onClickExit}>{vocabulary.leave[lang]}</li>
          </ul>
        )}
      </div>
    </>
  );
}

export default HamburgerMenu;
