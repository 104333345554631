import React, {
  createContext,
  useContext,
  useState,
  useRef,
} from 'react';

const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const intervalIdRef = useRef(null);
  const startTimer = (data) => {
    if (!isRunning) {
      setIsRunning(true);
      clearInterval(intervalIdRef.current);

      const startTime = Date.now() - data;

      intervalIdRef.current = setInterval(() => {
        const currentTime = Date.now();
        const elapsed = currentTime - startTime;
        setTime(elapsed);
      }, 100);
    }
  };

  const stopTimer = (initialState) => {
    setIsRunning(false);
    clearInterval(intervalIdRef.current);
    setTime(initialState);
  };

  const resetTimer = () => {
    stopTimer();
  
    setTime(0);
  };

  const getTimerState = () => ({
    time,
    isRunning,
  });

  return (
    <TimerContext.Provider
      value={{ startTimer, stopTimer, resetTimer, getTimerState }}
    >
      {children}
    </TimerContext.Provider>
  );
};

export const useTimer = () => {
  return useContext(TimerContext);
};
