import Header from './Header';

export default function Layout({
  children,
  showCurrentSessionInfo,
  showMenu,
  showDuration,
  lang,
  videoName,
}) {



  return (
    <div className="layout">
      <div className="header-layout">
        <Header
          showCurrentSessionInfo={showCurrentSessionInfo}
          showMenu={showMenu}
          showDuration={showDuration}
          lang={lang}
          videoName={videoName}
        />
        <div />
      </div>

      {/* <hr className="upper-line" />*/}
      <div>{children}</div>
    </div>
  );
}
