import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GlobalContext from '../GlobalContext';

import arrowBack from '../images/arrow-back.png';
import GoogleAuth from '../Components/GoogleAuth';
import { useSearchParams } from 'react-router-dom';
import { vocabulary } from '../helper/vocabulary';
export default function SignUpScreen() {
  const { GlobalActions} = useContext(GlobalContext);
  const [email, setUserEmail] = useState('');
  const [password, setUserPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [surname, setSurname] = useState('');
  const [age, setAge] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState(false);
  const [queryParameters] = useSearchParams();
  const [showSignUpError, setShowSignUpError] = useState(false);
  const showId = queryParameters.get('id') + '';
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [code, setCode] = useState(true);
  const [codeError, setCodeError] = useState(false);
  const navigate = useNavigate();

  const { langSelected } = useContext(GlobalContext);

  const [lang, setLang] = useState(0);

  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password === confirmPassword) {
      setPasswordsMatch(true);
  
    } else {
      setPasswordsMatch(false);

      return;
    }

    let dataToSend = {
      email: email,
      password: password,
      userName: userName,
      age: age,
      gender: gender,
      surname: surname,
      showId: showId,
      code: code,
      userType: 'user',
    };

    const res = async () => {
      let res = await GlobalActions.signUp(dataToSend);

      if (res === 'User already exists.') {
    
        setUserEmail('');
        setShowSignUpError(true);
      } else if (res === 'Code incorrect.') {
        setCodeError(true);
      } else if (res === 'ok') {
        navigate('/email-sent');
      } else {
        console.log('unknown error ocurred during sign in api call');
      }
    };

    res();
  };

  const handleInputChange = (e) => {
    const inputText = e.target.value;

    setAge(inputText);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setShowSignUpError(false);
    // Regular expression to validate email format
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (emailRegex.test(email) || email === '') {
      setUserEmail(email);
      setShowEmailErrorMessage(false);
    } else {
      setShowEmailErrorMessage(true);
    }
  };
  const [gender, setGender] = useState('');
  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setUserPassword(password);

    // Regular expression to check for consecutive repeating symbols
    const consecutiveRepeatingRegex = /(.)\1{2,}/;

    if (password.length < 6) {
      setPasswordError(vocabulary.passwordLongitudError[lang]);
    } else if (consecutiveRepeatingRegex.test(password)) {
      setPasswordError(vocabulary.passwordRepeatSymbolsError[lang]);
    } else {
      setPasswordError('');
    }
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleBackToLogin = () => {
    navigate('/login/?id=' + showId);
  };

  const handleCodeChange = (event) => {
    setCodeError(false);
    setCode(event.target.value);
  };
  return (
    <div className="login-screen">
      <div className="arrow-back-div" onClick={handleBackToLogin}>
        <img className="arrow-back" src={arrowBack} alt="logo" />
      </div>
      <div className="text-signup-up">
        <h3>{vocabulary.register[lang]}</h3>
      </div>

        

      <div className="login-group">




        <form onSubmit={handleSubmit}>
          <div className="form-group">
          <div className="labels-input">
              <label htmlFor="code">{vocabulary.enterCode[lang]}</label>
            </div>
            <div className="input-div">
              <input
                className="input-login"
                type="text"
                id="code"
                placeholder={vocabulary.code[lang]}
                onChange={handleCodeChange}
                required
              />
            </div>
            {codeError && (
              <div className="error-message">
                {vocabulary.incorrectCode[lang]}
              </div>
            )}
      <GoogleAuth
          setShowSignUpError={setShowSignUpError}
          setCodeError={setCodeError}
          code={code}
          lang={lang}
        />
            <div className="signup-names">
              <div className="name-inputs">
                <label htmlFor="name">{vocabulary.userName[lang]}</label>
                <div className="input-div-surname">
                  <input
                    className="input-login"
                    type="text"
                    id="name"
                    autoComplete="name"
                    placeholder={vocabulary.userName[lang]}
                    onChange={(e) => setUserName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="gap" />

              <div className="name-inputs">
                <label htmlFor="surname">{vocabulary.surname[lang]}</label>
                <div className="surname-inputs">
                  <div className="input-div-surname">
                    <input
                      className="input-login"
                      type="text"
                      id="family-name"
                      autoComplete="family-name"
                      placeholder={vocabulary.surname[lang]}
                      onChange={(e) => setSurname(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="labels-input">
              <label htmlFor="email">{vocabulary.email[lang]}</label>
            </div>
            <div className="input-div">
              <input
                className="input-login"
                type="text"
                id="email"
                autoComplete="email"
                placeholder={vocabulary.email[lang]}
                onChange={handleEmailChange}
                required
              />
            </div>
            {showEmailErrorMessage && (
              <div className="error-message">{vocabulary.wrongEmail[lang]}</div>
            )}
            <div className="labels-input">
              <label htmlFor="password">{vocabulary.password[lang]}</label>
            </div>
            <div className="input-div">
              <input
                className="input-login"
                type="password"
                id="password"
                autoComplete="password"
                placeholder={vocabulary.password[lang]}
                onChange={handlePasswordChange}
                required
              />
            </div>
            {passwordError && (
              <div className="error-message">{passwordError}</div>
            )}
            <div className="labels-input">
              <label htmlFor="password">
                {vocabulary.confirmPassword[lang]}
              </label>
            </div>
            <div className="input-div">
              <input
                className="input-login"
                type="password"
                id="confirm-password"
                autoComplete="password"
                placeholder={vocabulary.confirmPassword[lang]}
                onChange={handleConfirmPasswordChange}
                required
              />
            </div>
            {!passwordsMatch && (
              <div className="error-message">
                {vocabulary.passwordDoesNotMatch[lang]}
              </div>
            )}
            <div className="labels-input">
              <label htmlFor="age">{vocabulary.birthDate[lang]}</label>
            </div>
            <div className="input-div">
              <input
                className="input-login"
                type="date"
                id="age"
                placeholder="DD/MM/AAAA"
                autoComplete="bday"
                value={age}
                onChange={handleInputChange}
                required
              />
            </div>

        
              <div className="gender-text">
                <label>{vocabulary.gender[lang]}</label>
              </div>
              <div className="gender-box">
                <div>
                  <input
                    type="radio"
                    id="male"
                    name="gender"
                    value="male"
                    checked={gender === 'male'}
                    onChange={handleGenderChange}
                  />
                  <label htmlFor="male">{vocabulary.male[lang]}</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    value="female"
                    checked={gender === 'female'}
                    onChange={handleGenderChange}
                  />
                  <label htmlFor="female">{vocabulary.female[lang]}</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="other"
                    name="gender"
                    value="other"
                    checked={gender === 'other'}
                    onChange={handleGenderChange}
                  />
                  <label htmlFor="other">
                    {vocabulary.notYourBusiness[lang]}
                  </label>
                </div>
              </div>
          
        






            {showSignUpError && (
              <div className="error-message">{vocabulary.emailUsed[lang]}</div>
            )}
            <button type="submit" className="login-button">
              {vocabulary.register[lang]}
            </button>
           
          </div>
        </form>
  
      </div>
    </div>
  );
}
