import { useEffect } from 'react';
import { getShowInfo, getReaction, getImage } from '../api/workFlowApi';
import Layout from './Layout/Layout';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import GlobalContext from '../GlobalContext';

export default function EndedShow() {
  const [queryParameters] = useSearchParams();
  const showId = queryParameters.get('id') + '';
  const [show, setShow] = useState(null);
  const [reaction, setReaction] = useState([]);
  const [comments, setComments] = useState(0);
  const [blobUrl, setBlobURL] = useState(null);
  const [reactionQuantity, setReactionQuantity] = useState(0);
  const navigate = useNavigate();
 
  const { langSelected } = useContext(GlobalContext);
  const [lang, setLang] = useState(0);
  const [load, setLoad] = useState(false);
  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);
  
 
 
 
 
 
 
  useEffect(() => {
    let getShow = async () => {
      let resultGetInfo = await getShowInfo(showId);
      setShow(resultGetInfo.data.currentShowInfo);
      let reactionResult = await getReaction(showId);

      let image = await getImage(
        resultGetInfo.data.currentShowInfo.videoId._id
      );

      if (image) {
        let bufferObject = JSON.parse(image.data[0].blob);
        const uint8Array = new Uint8Array(bufferObject.data);
        let blob = new Blob([uint8Array], { type: bufferObject.type });
        setBlobURL(URL.createObjectURL(blob));
      }

      const resultComments = reactionResult.data.votesFound.reduce(
        (accumulator, val) => {
          if (val.annotation) {
            accumulator++;
          }
          return accumulator;
        },
        0
      );

      setReactionQuantity(reactionResult.data.votesFound.length);

      setComments(resultComments);
    };
    getShow();
  }, []);



  const handleEnter = () => {
    navigate('/my-shows');
  };
  return (
    blobUrl && (
      <Layout showMenu={true} lang={lang}>
        <div className="main-screen">
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '50vh',
              flexDirection: 'column',
              backgroundImage: `url(${blobUrl})`,
              backgroundSize: 'auto',
              backgroundPosition: 'center',
              alignItems: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          ></div>
          <div className="text-div-history">
            <div className="header-text-line">Sesión :</div>
            <div className="show-result-and-name">
              <div className="header-text-line">{show?.showName}</div>
              <div className="ended-show-status">Sesión finalizada </div>
            </div>
            <div className="users-opinions-div">
              <div className="total-reactions-div">
                <div className="header-text-line">Total reacciones:</div>
                <div className="header-text-line">{reactionQuantity}</div>
              </div>
              <div className="line-old-shows" />
              <div className="total-reactions-div">
                <div className="header-text-line">Total comentarios:</div>
                <div className="header-text-line">{comments}</div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  );
}
