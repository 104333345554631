import React, { useContext } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import GlobalContext from '../GlobalContext';
import { useNavigate } from 'react-router-dom';
import googleIcon from '../images/google-logo.png';
import { vocabulary } from '../helper/vocabulary';
export default function GoogleAuth({
  login,
  setShowSignUpError,
  setShowLogInError,
  code,
  setCodeError,
  lang,
}) {
  const { GlobalActions} = useContext(GlobalContext);
  const navigate = useNavigate();

  return (
    <div className="google-button">
      <img
        className="google-icon"
        src={googleIcon}
        alt="arrow back"
        width={'20px'}
      />

      {vocabulary.withGoogle[lang]}
      <div className="hided">
        <GoogleLogin
              width={500}
          onSuccess={(credentialResponse) => {
            if (!login) {
              let res = async () => {
                credentialResponse.code = code;
                let result = await GlobalActions.signUp(credentialResponse);
               
                if (result === 'User already exists.') {
                  setShowSignUpError('El usuario ya existe');
                  return;
                } else if (result === 'Code incorrect.') {
                  setCodeError(true);
                  return;
                }
                navigate('/main');
              };
              res();
            } else {
              let res = async () => {
                let result = await GlobalActions.logIn(credentialResponse);

                if (result === 'bad credentials') {
                  setShowLogInError(true);
                  return;
                }
                if (result === 'ok') {
                  navigate('/main');
                }
              };
              res();
            }
          }}
          onError={() => {
            console.log('Login Failed');
          }}
        />
      </div>
    </div>
  );
}
