import Layout from './Layout/Layout';
import GlobalContext from '../GlobalContext';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { vocabulary } from '../helper/vocabulary';

export default function MyShows() {
  const { state, } = useContext(GlobalContext);


  const { langSelected } = useContext(GlobalContext);

  const [lang, setLang] = useState(0);

  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);

  const navigate = useNavigate();


  return (
    <Layout showMenu={true} lang={lang}>
      <div className="my-shows">
          {state.currentShowInfo?.currentStatus!=="ended"? <> <div className="div-inside-button-shows">
          <div className="shows-name-text-in-button">
            {state.currentShowInfo?.showName.toUpperCase()} &nbsp;&nbsp;
            {state.currentShowInfo?.showStartTime.split(' ')[1]}
          </div>

      <div className="link-text-inside-button">{state.currentShowInfo?.showName&&vocabulary.now[lang]}</div>
        </div>
        <div className="line-menu" />
        
        </>
        
        :null}
        <div className="passed-show-text">{vocabulary.oldShows[lang]} </div>

        {state.userInfo.showsParticipated.forEach((val) => {
          if (val.currentStatus === 'ended') {
            const onClickGo = () => {
              navigate('/ended-show?id=' + val.showId);
            };
            return (
              <div className="old-shows-parent-div" key={val._id}>
                <div className="ended-shows">
                  <div className="old-shows-name">
                    {val.showName.toUpperCase()}
                  </div>
                  <div className="ended-show-status">
                    {vocabulary.showEnded[lang]}
                  </div>
                  <div className="old-shows-name-arrow" onClick={onClickGo}>
                    〉
                  </div>
                </div>

                <div className="line-old-shows"></div>
              </div>
            );
          }
        })}
      </div>
    </Layout>
  );
}
