export const vocabulary = {
  //login
  buttonSubmit: ['Submit', 'Enviar'],
  myAccount: ['My account', 'Mi cuenta'],
  movies: ['Movies', 'Películas'],
  leave: ['Exit', 'Salir'],
  startSession: ['Start Screening', 'Iniciar Proyección'],
  userName: ['User name', 'Nombre de Usuario'],
  password: ['Password', 'Contraseña'],
  errorMailPassword: [
    'Wrong Email or Password"',
    'Correo o Contraseña Incorrectos',
  ],
  passwordForgotten: ['Forgot Your Password?', '¿Olvidaste Tu Contraseña?'],
  enter: ['Enter', 'Entrar'],
  doNotHaveAccount: ['Want to Register?', '¿Quieres Registrarte?'],
  startWithGoogle: ['Or Sign in with Google', 'O Iniciar Sesión con Google'],
  doNotRegistered: ['Not Registered?', '¿No Registrado?'],
  withGoogle: ['  With Google', ' Con Google'],
  //sign-up
  passwordLongitudError: [
    'Must Be at Least 6 Characters Long',
    'Debe Tener al Menos 6 Caracteres',
  ],
  passwordRepeatSymbolsError: [
    'No Repeating Characters Allowed',
    'No Se Permiten Caracteres Repetidos',
  ],
  register: ['Register', 'Registrar'],
  namesAndSurnames: ['Name and Surname', 'Nombres y Apellidos'],
  email: ['Email', 'Correo'],
  confirmPassword: ['Confirm Password', 'Confirma Tu Contraseña'],
  passwordDoesNotMatch: [
    'Passwords Do Not Match.',
    'Las Contraseñas No Coinciden.',
  ],

  enterCode: ['Enter Code', 'Ingresa el Código'],
  wrongEmail: ['Invalid Email Format.', 'Formato de Correo Inválido.'],
  emailUsed: ['Email Already in Use', 'Correo Ya en Uso'],
  code: ['Code', 'Código'],
  //confirmation code
  confirmationCode: ['Confirmation Code', 'Código de Confirmación'],
  sendCode: ['Send Code', 'Enviar Código'],
  incorrectCode: ['Incorrect Code', 'Código Incorrecto'],
  emailSent: [
    'Check Your Mailbox to Activate Account',
    'Revisa Tu Correo para Activar la Cuenta',
  ],
  sessions: ['Screenings', 'Proyecciones'],
  seeSessions: ['View Screenings', 'Ver Proyecciones'],
  details: ['Tech Specs.', ' Especificaciones Técnicas.'],
  cancel: ['Cancel', 'Cancelar'],
  erase: ['Delete', 'Borrar'],
  //sessions
  sessionSearch: ['Search Screenings', 'Buscar Proyecciones'],
  session: ['Screening ', 'Proyección'],
  sessionProgrammed: ['Screening Scheduled', 'Proyección Programada'],
  sessionEnded: ['Screening Ended', 'Proyección Finalizada'],
  changeSession: ['Change Screening', 'Cambiar Proyección'],
  deleteSession: ['Delete Screening', ' Borrar Proyección'],
  sessionName: ['Screening Name', 'Nombre de la Proyección'],
  date: ['Date', 'Fecha'],
  time: ['Time', 'Hora'],
  thanks: ['Thanks a Lot', 'Muchas Gracias'],
  all: ['All', 'Todos'],
  ended: ['Ended', 'Terminada'],
  //user menu
  phoneNumber: ['Phone Number ', 'Número de Teléfono'],
  direction: ['Address', 'Dirección'],
  occupation: ['Occupation', 'Ocupación'],
  civilStatus: ['Marital Status', 'Estado civil'],
  city: ['City', 'Ciudad'],
  gender: ['Gender', 'Genero'],
  educationLevel: ['Education Level', 'Nivel de Educación'],
  interest: ['Interests', 'Intereses'],
  updateAccount: ['Update Account', 'Actualizar Cuenta'],
  surname: ['Family Name', 'Apellido'],
  birthDate: ['Birth Date', 'Fecha de Nacimiento'],
  male: ['Male', 'Hombre'],
  female: ['Female', 'Mujer'],
  notYourBusiness: ['Not Your Business', 'No es Asunto Tuyo'],
  ///main Screen
  howItWorks: ['How Does it Work?', '¿Cómo Funciona?'],
  itStartsRightNow: [
    'Screening Starting Soon',
    'La Proyección iniciará pronto',
  ],
  wait: ['Please Wait...', 'Por favor espere...'],
  duration: ['Duration', 'Duración'],
  //menu
  actualShow: ['Current Screening', 'Proyección Actual'],
  myShows: ['My Screenings', 'Mis Proyecciones'],
  privacyAndTerms: ['Privacy and Terms', 'Privacidad y Términos'],
  //old shows
  now: ['Now', 'Ahora'],
  oldShows: ['Past Screenings', 'Proyecciones Anteriores'],
  showEnded: ['Screening Ended', 'Proyeccion Finalizada '],
  thanksForJoin: ['Thanks for Joining,', 'Gracias por Participar,'],
  //how its work
  howItsWork: ['How it Works', '¿Cómo funciona?'],
  howItsWorkText: [
    'Swipe right to like a scene, left to dislike. Swipe up or down to leave comments.',
    'Desliza a la derecha para gustar una escena, a la izquierda para no gustar. Desliza hacia arriba o abajo para dejar comentarios.',
  ],
  imUnderstand: ['Understood', 'Entendido'],
  //forms
  whatDoYouThink: ['Enter Your Comments...', 'Ingresa tus comentarios...'],
  send: ['Send', 'Enviar'],
};
